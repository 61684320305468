import {useState, useRef} from 'react';
import {inquiry} from './api';

function App() {
  const [formState, setFormState] = useState(0);

  return (
      <>
        <div className="App">

          <div className="content">
            <div className="dummy"></div>
            <div className="content-inner">
              <div className="logo">
                <svg version="1.1" id="logo_svg"
                     y="0px" viewBox="0 0 430 120"
                     style={{'enableBackground': 'new 0 0 430 120'}}>
                  <g>
                    <rect x="5" y="16.46" className="st0" width="16.75"
                          height="87.04"/>
                    <path className="st0" d="M147.64,103.53c24.13,0,43.76-19.62,43.76-43.74V16.46h-16.76v43.33c0,14.89-12.11,27-27,27
		c-14.88,0-26.99-12.11-26.99-27V16.46H103.9v43.33C103.9,83.91,123.52,103.53,147.64,103.53z"/>
                    <path className="st0" d="M299.81,16.46l-30.84,60.32c-3.19,6.25-7.18,9.99-10.65,10.01h-0.01c-3.48,0-7.47-3.71-10.7-9.94l-31.4-60.39
		h-18.88l35.41,68.12c8.57,16.47,19.67,18.96,25.57,18.96c0.02,0,0.05,0,0.07,0c5.89-0.02,17.03-2.53,25.51-19.13l34.74-67.95
		H299.81z"/>
                    <path className="st0" d="M371.26,16.46h-17.19c-24.05,0-43.62,19.51-43.74,43.54c0.12,24.03,19.69,43.54,43.74,43.54h17.19
		c24.05,0,43.62-19.51,43.74-43.54C414.88,35.97,395.31,16.46,371.26,16.46z M371.26,86.79h-17.19c-14.81,0-26.86-12-26.98-26.79
		c0.12-14.78,12.17-26.78,26.98-26.78h17.19c14.81,0,26.87,12,26.98,26.78C398.13,74.79,386.07,86.79,371.26,86.79z"/>
                    <path className="st0" d="M48.43,48.99l50.19-32.48H67.78L39.32,34.93c-9.4,6.09-14.78,14.91-14.75,24.21
		c0.04,9.29,5.48,18.07,14.94,24.09l31.84,20.27h31.19L48.51,69.1c-4.62-2.94-7.17-6.5-7.18-10.02
		C41.31,55.55,43.83,51.96,48.43,48.99z"/>
                  </g>
                </svg>
              </div>

              <div className="main-description">
                KUVO is evolving to provide more value to electronic music
                creators
                and venues in the future.<br/>
                From September 30, 2022, KUVO will no longer offer the
                functionality
                to host live mixes,
                playlists, club listings, and public profiles.<br/>
                We thank you for your continued support and encourage you
                to stay tuned for news on the next stage of KUVO’s evolution.
              </div>

              <div className="sub-description">
                For any support, please
              </div>

              <a className="form-button" href={"https://support.pioneerdj.com/hc/en-us/categories/4417227498393"}>
                Contact us
              </a>
            </div>

            <a id={'footer-link'} href={'https://alphatheta.com/'}
               target={'_blank'}>&copy;AlphaTheta</a>
          </div>


        </div>
      </>
  );
}

export default App;
